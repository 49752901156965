import React, { useEffect, useState } from 'react';
import { fetchAllusers } from '../apis/user';
import profileAdv from '../assets/images/advocate.png'

const Counter = () => {
  const [totalCount, setTotalCount] = useState(0)
  const [activeCount, setActiveCount] = useState(0)
  const [deadCount, setDeadCount] = useState(0)
  useEffect(() => {
    initFetch()
  }, [])
  const initFetch = async () => {
    const _users = await fetchAllusers()
    setTotalCount(_users?.length || 0)
    setActiveCount(_users?.filter((_usr) => _usr.memberStatus.includes('Active') || _usr.memberStatus.includes('active'))?.length || '0')
    setDeadCount(_users?.filter((_usr) => _usr.memberStatus.includes('Dead') || _usr.memberStatus.includes('dead'))?.length || '0')
  }
  return (
    <div className='container px-5 md:px-none min-w-full flex flex-wrap items-center justify-center '>
      <div
        style={{
          boxShadow: "inset 0px 0px 5px #a2b6c7",
          padding: "20px 25px",
          borderRadius: "5px"
        }}
        className='w-full md:w-auto member_count_inner m-1  flex items-center justify-center flex-col'
      >
        <span>
          <img src={profileAdv} alt='ADV' />
        </span>
        <p className='font-bold text-2xl my-2'>{totalCount}</p>
        <h3 className='-mt-3'>Total Members</h3>
      </div>
      <div
        style={{
          boxShadow: "inset 0px 0px 5px #a2b6c7",
          padding: "20px 25px",
          borderRadius: "5px"
        }}
        className='w-full md:w-auto member_count_inner m-1  flex items-center justify-center flex-col'
      >
        <span>
          <img src={profileAdv} alt='ADV' />
        </span>
        <p className='font-bold text-2xl my-2'>{activeCount}</p>
        <h3 className='-mt-3'>Active Members</h3>
      </div>
      <div
        style={{
          boxShadow: "inset 0px 0px 5px #a2b6c7",
          padding: "20px 25px",
          borderRadius: "5px"
        }}
        className=' w-full md:w-auto member_count_inner m-1  flex items-center justify-center flex-col'
      >
        <span>
          <img src={profileAdv} alt='ADV' />
        </span>
        <p className='font-bold text-2xl my-2'>{deadCount}</p>
        <h3 className='-mt-3'>Dead Members</h3>
      </div>

    </div>
  )
}

export default Counter
