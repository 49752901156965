/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal, DatePicker } from 'antd'
import moment from 'moment'
import * as dayjs from "dayjs";

import {
  DDMMYYYY,
  formatDateToDDMMYYYY,
  getBaseAPIRootUrl,
  getDelayedFee,
  getLoanStatus,
  getToken,
  toastConfigColoured,
} from '../../utils/helper'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import Spinner from '../../components/Spinner'
import CardHeader from '../../components/CardHeader'
import MemberSearch from '../../components/Mainbody/MemberSearch'
import VoterSearch from '../../components/Mainbody/VoterSearch'
import Notice from '../../components/Mainbody/Notice'
import Api from '../../apis/api'

import bookLogo from '../../assets/images/book-logo.png'
import ReactSelect from 'react-select'
import axios from '../../config/axios'
import News from '../../components/Mainbody/News'
const commonInputClassNames = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

const BookDetails = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const _paramsId = searchParams.get('id')

  const user = JSON.parse(localStorage.getItem('profile') || '{}') || {}

  const [bookLoansFiltered, setBookLoansFiltered] = useState([])
  const [isLoadding, setIsLoadding] = useState(false)
  const [bookDetails, setBookDetails] = useState({})

  const [addEditModalOpen, setAddEditModalOpen] = useState(false)
  const [selectedLoanIdForDelete, setSelectedLoanIdForDelete] = useState('')
  const [selectedUser, setSelectedUser] = useState({})
  const [users, setUsers] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [allocateDate, setAllocateDate] = useState(dayjs())



  useEffect(() => {
    if (_paramsId) {
      initFetch()
    }
  }, [_paramsId])

  const initFetch = async () => {
    setIsLoadding(true)
    const bdRes = await Api.get('/books/' + _paramsId)
    if (bdRes?.data) {
      setBookDetails(bdRes?.data)
    }
    if (user?._id) {
      const bLRes = await Api.get(`/books/book_loan?bookId=${_paramsId}${!user.isAdmin ? '&userId=' + user._id : ''}`)
      console.log('BlRes', bLRes)
      if (bLRes && bLRes.length) {
        setBookLoansFiltered(bLRes)
      } else {
        setBookLoansFiltered([])
      }
    }
    if (user.isAdmin) {
      const usersRes = await Api.get('/user/names')
      if (usersRes?.users) {
        setUsers(usersRes?.users)
        setUserOptions(usersRes?.users?.map((_it) => ({ label: _it.nameEn + ' - ' + _it.phoneEn, value: _it._id })))
      }
    }

    setIsLoadding(false)
  }

  // const fetchBookLoans = 


  const resetModalData = () => {
    setSelectedLoanIdForDelete('')
    setSelectedUser({})
    setAllocateDate(dayjs())
  }

  //// Paginate Start
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffset] = useState(0);
  const [paginationData, setPaginationData] = useState([]);
  const itemsPerPage = 20;

  useEffect(() => {
    const temps =
      bookLoansFiltered.map((_it, _in) => ({ ..._it, _idx: _in })) || [];
    setPaginationData(temps);
  }, [bookLoansFiltered]);

  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(paginationData.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(paginationData.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, paginationData]);

  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % paginationData.length;
    setItemOffset(newOffset);
  };
  //// Paginate End

  const getImgUrl = (_imgExt) => _imgExt ? getBaseAPIRootUrl() + 'books/' + _imgExt : bookLogo


  const handleBookLoanDelete = async () => {
    console.log('selected book id: ', selectedLoanIdForDelete)
    if (!selectedLoanIdForDelete) {
      return
    }
    let _data = await Api.delete('/books/book_loan/' + selectedLoanIdForDelete)
    console.log('book loan delete data: ', _data)
    setSelectedLoanIdForDelete('')
    if (_data?.success) {
      toast.info(_data.message, toastConfigColoured);
      setSelectedLoanIdForDelete("");
      initFetch();
    } else {
      toast.warn(
        "Something went wrong! Please try again!",
        toastConfigColoured
      );
    }
  };

  const handleBookLoanAdd = async () => {
    if (!selectedUser?.value) {
      toast.info("Lawyer must be selected!", toastConfigColoured);
      return;
    }


    const bookLoanData = {
      allocateDate,
      loan_status: 'onloan',
      bookId: _paramsId,
      userId: selectedUser?.value,
      adminId: user?._id,
    };

    console.log('bookLoanData: ', bookLoanData)


    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getToken(),
      },
    }

    let _res = await axios.post('/books/book_loan', bookLoanData, config);

    console.log("add book loan _res", _res);
    if (_res && (_res.status === 200 || _res.status === 201)) {
      toast.info("Successfully Created", toastConfigColoured);

      setAddEditModalOpen(false);
      resetModalData();
      initFetch();
    } else {
      toast.warn(
        _res.message || "Something went wrong! Please try again.",
        toastConfigColoured
      );
    }
  };


  const updateAsReturned = async (_blId) => {
    const bookLoanData = {
      loan_status: 'returned',
      bookId: _paramsId,
    };

    console.log('bookLoan update Data: ', bookLoanData)


    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getToken(),
      },
    }

    let _res = await axios.put('/books/book_loan/' + _blId, bookLoanData, config);

    console.log("update book loan _res", _res);
    if (_res && (_res.status === 200 || _res.status === 201)) {
      toast.info("Successfully Updated", toastConfigColoured);

      setAddEditModalOpen(false);
      resetModalData();
      initFetch();
    } else {
      toast.warn(
        _res.message || "Something went wrong! Please try again.",
        toastConfigColoured
      );
    }
  };


  return (
    <div className="mh-container container-9-3 min-h-screen ml-0 gap-8 book-details-container ">

      {/* delete loan  */}
      <Modal
        open={selectedLoanIdForDelete}
        title={`Delete Book confirmation`}
        className="user-update-modal"
        onCancel={() => {
          setSelectedLoanIdForDelete('');
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setSelectedLoanIdForDelete('');
            }}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleBookLoanDelete}
          >
            Confirm
          </button>,
        ]}
      >
        <div className="form-container one-row user">
          <p>Are you sure to delete this book loan?</p>
        </div>
      </Modal>

      {/* add/edit book  */}
      <Modal
        bodyStyle={{
          backgroundColor: "white",
        }}
        open={addEditModalOpen}
        title="Add Book Loan"
        className=""
        onCancel={() => {
          setAddEditModalOpen(false);
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setAddEditModalOpen(false);
            }}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleBookLoanAdd}
          >
            Submit
          </button>,
        ]}
      >
        <div className="update-form-container modalInput">
          <div className="grid gap-6 mb-6 md:grid-cols-1">

            <div>
              <label
                htmlFor="Category"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Select Lawyer
              </label>
              <ReactSelect
                options={userOptions}
                isSearchable={true}
                onChange={(newV) => setSelectedUser(newV)}
                value={selectedUser}
              />
            </div>
            <div>
              <label
                htmlFor="website"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Allocate Date
              </label>
              <DatePicker
                value={allocateDate}
                onChange={(val) => setAllocateDate(val)}
                className={commonInputClassNames + ' antd-datepicker-custom'}
                disabledDate={(current) => current && (current.day() === 5 || current.day() === 6)}
              />
            </div>
          </div>
        </div>
      </Modal>

      {isLoadding ? (
        <Spinner width="200" color="#4fa94d" />
      ) : (
        <>

          <div className="common-hover custom-card imx-0 custom-column ">
            <CardHeader title={bookDetails?.title || "Book Details"} />

            <div className="book-details">
              <div className="img-and-details grid md:grid-cols-[auto_1fr] gap-5 md:gap-10">
                <div className="img w-80">
                  <img className=' border border-xl p-2 mb-2' src={getImgUrl(bookDetails?.imageUploaded)} alt={bookDetails?.title || "Book Image"} />
                </div>
                <div className="details grid content-between mb-2">
                  <div className="details-p">
                    <p className='text:lg md:text-xl my-2'>Title: <h2 className='inline'>{bookDetails?.title}</h2></p>
                    <p className='text:lg md:text-xl my-2'>Stock: <strong>{bookDetails?.quantity}</strong></p>
                    <p className='text:lg md:text-xl my-2'>Author: <strong>{bookDetails?.author}</strong></p>
                    <p className='text:lg md:text-xl my-2'>Stock Date: <strong>{formatDateToDDMMYYYY(bookDetails?.stockDate)}</strong></p>
                    <p className='text:lg md:text-xl my-2'>Publishing Date: <strong>{formatDateToDDMMYYYY(bookDetails?.publishedDate)}</strong></p>
                  </div>
                  {user.isAdmin && (
                    <div className="btns text-end">
                      <button
                        key="back"
                        className="custom-button custom-button-sm"
                        onClick={() => {
                          setAddEditModalOpen(true)
                        }}
                        disabled={bookDetails?.quantity === 0}
                      >
                        {bookDetails?.quantity === 0 ? 'Allocated' : 'Allocate'}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {bookLoansFiltered && bookLoansFiltered.length > 0 && (
              <div className="table-container my-5 w-[calc(100vw-93px)] md:w-full">
                <h2 className='mb-2'>Allocation List</h2>
                <div className="table-container-inner overflow-x-auto w-full">
                  <table className="md:w-[100%] w-[600px]  table-design">
                    <thead>
                      <tr className="text-[14px] text-[#444] leading-[20px] w-full">
                        <th>#</th>
                        <th>User</th>
                        <th>Phone</th>
                        <th>Allowcate Date</th>
                        <th>Status</th>
                        <th>Delayed Fee (Tk)</th>
                        {user.isAdmin && (<th>Action</th>)}
                      </tr>
                    </thead>

                    <tbody className="w-[100%]">
                      {currentItems.map((_item, _in) => (
                        <tr
                          className="text-[14px] text-[#444] leading-[18px] w-full "
                          key={_in}
                        >
                          <td>{_item._idx + 1}</td>
                          <td>{_item.userId?.nameEn}</td>
                          <td>{_item.userId?.phoneEn}</td>
                          <td>{formatDateToDDMMYYYY(_item.allocateDate)}</td>
                          <td>{getLoanStatus(_item)}</td>
                          <td>{getDelayedFee(_item.allocateDate)}</td>
                          {user.isAdmin && (
                            <td className='grid gap-1'>
                              <button
                                key="back"
                                className="custom-button custom-button-xs"
                                onClick={() => {
                                  setSelectedLoanIdForDelete(_item._id)
                                }}
                              >
                                Delete
                              </button>
                              {_item.loan_status === 'onloan' && (
                                <button
                                  key="back"
                                  className="custom-button custom-button-xs"
                                  onClick={() => updateAsReturned(_item._id)}
                                >
                                  Returned
                                </button>
                              )}
                            </td>
                          )}

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>
            )}
          </div>
          <div className="custom-column  lg:mt-0 ">
            <div className="common-hover card-box-shadow-inset">
              <CardHeader title="Member Search" />
              <MemberSearch />
            </div>
            <VoterSearch />
            <Notice />
            <News />
          </div>
        </>
      )}
    </div>
  )
}

export default BookDetails
